import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | Imagine That",
  pageDescription:
    "Find out how a specialty gift basket store utilizes SpotOn Retail to manage its ecommerce products and expand its online customer reach.",
  title: "Imagining gift baskets online",
  subTitle: `How Imagine That gift store boosts online sales with SpotOn.`,
  businessName: "Imagine That",
  industry: "Retail",
  location: "Tallahassee, Florida",
  products: [
    spotonProducts.WEBSITE,
    spotonProducts.E_COMMERCE,
    spotonProducts.RETAIL,
    spotonProducts.LOYALTY,
  ],
  description:
    "In 2003, Jenny Wells created Imagine That—a store featuring customized gift baskets with handpicked local items. And through SpotOn, Wells found a reliable tech partner to help expand Imagine That’s online customer base.",
  quoteData: {
    imgName: "imagine-that.png",
    quote: `“When I spend money on a service, I want to feel like I’m getting value. And the value with my previous provider was not there. I didn’t have someone in my corner trying to boost my business and work with me. I thought, ‘what am I paying for?’ I’ve seen the value since using SpotOn. My account executive, Jerry, provides the same level of customer service that I provide to my people.”`,
    personName: "Jenny Wells",
    personTitle: "Owner, Imagine That",
  },
  goal: `Wells sought a technology partner with ecommerce solutions that would expand her digital presence, boost online sales, and deliver the customer support she needed whenever she picked up the phone.`,
  solution: `Through SpotOn Website and SpotOn Ecommerce, Imagine That established its digital storefront and expanded its customer reach with SEO. At the same time, SpotOn Loyalty keeps its customers coming back for more baskets.`,
  results: {
    title: `The results`,
    stats: [
      { title: `500%`, description: `increase in online sales` },
      {
        title: `Increase`,
        description: `in new customers`,
      },
    ],
  },
};

export const relatedSuccessStories = [
  {
    title: "Getting more regulars with rewards",
    imageName: "strows-dry-cleaners.png",
    industry: resourceIndustries.RETAIL,
    tag: resourceTags.CASE_STUDY,
    date: "2022-05-27",
    href: "/case-studies/strows-dry-cleaners",
    goals: [
      resourceGoals.BUILD_CUSTOMER_LOYALTY,
      resourceGoals.GET_SMARTER_DATA,
    ],
  },
  {
    title: "Growing awareness for autism",
    imageName: "ability-matters.png",
    industry: resourceIndustries.PROFESSIONAL_SERVICES,
    tag: resourceTags.CASE_STUDY,
    date: "2022-05-12",
    href: "/case-studies/ability-matters",
    goals: [
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
      resourceGoals.REACH_NEW_CUSTOMERS,
    ],
  },
  {
    title: "Making more dough with donuts",
    imageName: "delovelys-delicious-mini-donuts.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-04-04",
    href: "/case-studies/delovelys-delicious-mini-donuts",
    goals: [
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
      resourceGoals.REACH_NEW_CUSTOMERS,
      resourceGoals.STREAMLINE_OPERATIONS,
    ],
  },
];
